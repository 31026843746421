var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "header",
        {
          staticClass: "overflow-hidden mb-4 rounded-t-0",
          staticStyle: { "border-radius": "46px" },
        },
        [
          _c("input", {
            ref: "coverInput",
            attrs: { type: "file", accept: "image/*", hidden: "" },
            on: { input: _vm.pickCover },
          }),
          _c("input", {
            ref: "profileInput",
            attrs: { type: "file", accept: "image/*", hidden: "" },
            on: { input: _vm.pickProfile },
          }),
          _c(
            "v-img",
            {
              staticClass: "white--text align-end",
              attrs: {
                height: "250px",
                src: _vm.clinic.coverPic
                  ? _vm.clinic.coverPic
                  : require("@/assets/images/placeholder.png"),
                "lazy-src": require("@/assets/images/placeholder.png"),
              },
            },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "mb-2 ms-2 me-0",
                  attrs: { color: "grey", size: "92" },
                },
                [
                  _c(
                    "v-img",
                    {
                      staticClass: "border border-white border-4 relative",
                      attrs: {
                        src: _vm.clinic.profilePic
                          ? _vm.clinic.profilePic
                          : require("@/assets/images/placeholder.png"),
                        "lazy-src": require("@/assets/images/profile-circle.png"),
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "absolute center-xy bg-o70",
                          attrs: {
                            color: "white",
                            elevation: "0",
                            fab: "",
                            outlined: "",
                            small: "",
                          },
                          on: { click: _vm.selectProfile },
                        },
                        [
                          _c("iconly", {
                            attrs: { name: "camera", type: "light" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "absolute center-xy bg-o70",
                  attrs: {
                    color: "white",
                    elevation: "0",
                    fab: "",
                    outlined: "",
                    "x-large": "",
                  },
                  on: { click: _vm.selectCover },
                },
                [
                  _c("iconly", {
                    attrs: { name: "camera", type: "light", size: "3x" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "d-flex flex-column mb-4",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("span", { staticClass: "my-1 d-block" }, [
                _vm._v(_vm._s(_vm.$t("About"))),
              ]),
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  type: "text",
                  counter: "1000",
                  rules: _vm.descriptionRules,
                  required: "",
                },
                model: {
                  value: _vm.clinic.about,
                  callback: function ($$v) {
                    _vm.$set(_vm.clinic, "about", $$v)
                  },
                  expression: "clinic.about",
                },
              }),
              _c("span", { staticClass: "my-1 d-block" }, [
                _vm._v(_vm._s(_vm.$t("ID"))),
              ]),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  type: "text",
                  rules: _vm.usernameRules,
                  required: "",
                },
                model: {
                  value: _vm.clinic.uniqueCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.clinic, "uniqueCode", $$v)
                  },
                  expression: "clinic.uniqueCode",
                },
              }),
              _c("span", { staticClass: "my-1 d-block" }, [
                _vm._v(_vm._s(_vm.$t("Phone Number"))),
              ]),
              _c("v-combobox", {
                attrs: {
                  outlined: "",
                  "single-line": "",
                  rules: [(v) => !!v],
                  required: "",
                  multiple: "",
                  "append-icon": "",
                  delimiters: [",", "-", "."],
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [
                        _c(
                          "v-chip",
                          _vm._b(
                            {
                              key: JSON.stringify(data.item),
                              staticClass: "pe-2 ps-4",
                              attrs: {
                                small: "",
                                dense: "",
                                "input-value": data.selected,
                              },
                              on: {
                                "click:close": function ($event) {
                                  return data.parent.selectItem(data.item)
                                },
                              },
                            },
                            "v-chip",
                            data.attrs,
                            false
                          ),
                          [
                            _c("span", { staticClass: "ltr" }, [
                              _vm._v(_vm._s(data.item)),
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass:
                                  "primary white--text rounded-circle p-1",
                                attrs: { right: "", small: "", fab: "" },
                              },
                              [_vm._v("mdi-phone")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.phoneNumbers,
                  callback: function ($$v) {
                    _vm.phoneNumbers = $$v
                  },
                  expression: "phoneNumbers",
                },
              }),
              _c("span", { staticClass: "my-1 d-block" }, [
                _vm._v(_vm._s(_vm.$t("Contact description"))),
              ]),
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  type: "text",
                  counter: "1000",
                  rules: _vm.descriptionRules,
                  required: "",
                },
                model: {
                  value: _vm.clinic.contactDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.clinic, "contactDescription", $$v)
                  },
                  expression: "clinic.contactDescription",
                },
              }),
              _c("span", { staticClass: "my-1 d-block" }, [
                _vm._v(_vm._s(_vm.$t("Link"))),
              ]),
              _c("v-text-field", {
                staticClass: "ltr",
                attrs: {
                  outlined: "",
                  type: "text",
                  rules: _vm.usernameRules,
                  required: "",
                },
                model: {
                  value: _vm.clinic.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.clinic, "link", $$v)
                  },
                  expression: "clinic.link",
                },
              }),
              _c("span", { staticClass: "my-1 d-block" }, [
                _vm._v(_vm._s(_vm.$t("Address"))),
              ]),
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  type: "text",
                  counter: "1000",
                  rules: _vm.descriptionRules,
                  required: "",
                },
                model: {
                  value: _vm.clinic.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.clinic, "address", $$v)
                  },
                  expression: "clinic.address",
                },
              }),
              _c("span", [_vm._v(_vm._s(_vm.$t("Map")))]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mb-4 d-block",
              staticStyle: { width: "100%", height: "400px" },
            },
            [
              _c(
                "mapir",
                {
                  attrs: {
                    apiKey: _vm.mapirToken,
                    center: _vm.coordinates,
                    zoom: 13,
                  },
                  on: { load: _vm.onMapLoaded, click: _vm.mapOnClick },
                },
                [
                  _c("mapNavigationControl", {
                    attrs: { position: "top-right" },
                  }),
                  _c("mapScaleControl", { attrs: { position: "top-right" } }),
                  _c("mapMarker", {
                    attrs: {
                      coordinates: _vm.markerCoordinates,
                      color: "blue",
                      draggable: true,
                    },
                    on: {
                      "update:coordinates": function ($event) {
                        _vm.markerCoordinates = $event
                      },
                      dragend: _vm.getlatLng,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-footer",
        {
          staticClass: "pb-2 pt-0",
          staticStyle: { "z-index": "5000" },
          attrs: { fixed: "", app: "", paddless: "", color: "transparent" },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    large: "",
                    block: "",
                    loading: _vm.submit_loading,
                    disabled: _vm.submit_loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }